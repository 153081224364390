let Variables = {
  formWarm: false,
  test: 0,
}
declare global {
  interface Window {
    analytics: any;
  }
}

export default Variables
