import { graphql } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'

import { CTATargetId } from '../../../constants/GlobalDOM'
import useMicroCopy from '../../../hooks/globalContent/useMicroCopy'
import useAnimationLoop from '../../../hooks/useAnimationLoop'
import { useContactForm } from '../../../hooks/useContactForm'
import Blob from '../../../utils/blob-classes/Blob'
import { rtfToHtml } from '../../../utils/rtfToHtml'
import BaseForm from '../../_elements/BaseForm'
import ContentfulRichText from '../../_elements/ContentfulRichText'
import { RichTextJson } from '../../_elements/ContentfulRichText/ContentfulRichText'
import ExternalLink from '../../_elements/ExternalLink'
import CTAButton from '../../_elements/_buttons/CTAButton'
import CheckboxField from '../../_elements/_inputs/CheckboxField'
import InpuField from '../../_elements/_inputs/InputField'
import ActionString from '../../_functional/ActionString'
import LayoutContainer from '../../_layouts/LayoutContainer'

import styles from './sign-up-form-block.module.scss'

export const SignUpFormBlockTypename = 'ContentfulBlockSignUpForm'

export interface SignUpFormBlockProps {
  __typename: typeof SignUpFormBlockTypename

  emailConfirmationSubject?: string
  emailConfirmationTextV2?: any
  confirmationTitle?: string
  confirmationText?: { json: any }
  formId: string
  title?: string
  bodyText?: {
    json: any
  }
  buttonText: string
  slug: string
}

export default function SignUpFormBlock({
  emailConfirmationSubject,
  emailConfirmationTextV2,
  confirmationText,
  confirmationTitle,
  formId,
  title,
  bodyText,
  buttonText,
  slug,
}: SignUpFormBlockProps) {
  const nameField = useMicroCopy('form.name')
  const emailField = useMicroCopy('form.email')
  const phoneField = useMicroCopy('form.phone')
  const companyNameField = useMicroCopy('form.companyName')
  const mailingConsentField = useMicroCopy('form.mailingConsent')
  const errorMessage = useMicroCopy('form.error_message')
  const gdprField = useMicroCopy('form.gdpr')
  const recaptchaDisclaimer = useMicroCopy('form.recaptcha_disclaimer')

  const [isDisabled, setIsDisabled] = useState(true)
  const [hasGdprConsent, setHasGdprConsent] = useState(false)

  //set default to true because mailing checkbox is temporarily disabled
  const [hasMailingConsent, setHasMailingConsent] = useState(true)

  const [emailConfirmationMessage, setEmailConfirmationMessage] =
    useState<string>()

  useEffect(() => {
    if (emailConfirmationTextV2?.emailConfirmationTextV2) {
      const htmlEmailText = rtfToHtml(emailConfirmationTextV2?.json)
      setEmailConfirmationMessage(htmlEmailText)
    }
  }, [emailConfirmationTextV2])

  const {
    values,
    isLoading,
    hasSentSuccessfully,
    requestError,
    setValue,
    handleSubmit,
  } = useContactForm()

  useEffect(() => {
    if (hasGdprConsent) {
      return setIsDisabled(false)
    }
    return setIsDisabled(true)
  }, [hasGdprConsent])

  const pathElRef = useRef<SVGPathElement>(null)

  const [loadingBlob] = useState(
    () =>
      new Blob({
        pointCount: 10,
        radius: 20,
        pointSpeedOffset: 0.0075,
        pointRadiusOffset: 0.25,
      })
  )

  const { start, stop } = useAnimationLoop((time) => {
    loadingBlob.update(time)
    pathElRef.current?.setAttribute('d', loadingBlob.toSvgPathD())
  })

  useEffect(() => {
    if (isLoading) {
      start()
      loadingBlob.appear()
    } else {
      loadingBlob.disappear()
      const timeoutId = setTimeout(stop, 500)
      return () => clearTimeout(timeoutId)
    }
  }, [isLoading])

  function onChangeMailingConsent(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setHasMailingConsent(!hasMailingConsent)
    setValue(e)
  }

  return (
    <LayoutContainer withMargin>
      <section className={styles.signUpForm} id={slug}>
        <div className={styles.textWrapper}>
          <h2>{title}</h2>
          {bodyText && <ContentfulRichText json={bodyText.json} />}
        </div>
        <div className={styles.formWrapper}>
          {!hasSentSuccessfully && (
            <BaseForm
              id={CTATargetId}
              onSubmit={(e) =>
                handleSubmit(
                  e,
                  formId,
                  emailConfirmationMessage,
                  emailConfirmationSubject
                )
              }
            >
              <InpuField
                label={nameField}
                name="name"
                value={values.name}
                onChange={setValue}
              />
              <InpuField
                type="email"
                label={emailField}
                name="mail"
                value={values.mail}
                onChange={setValue}
              />
              <InpuField
                type="tel"
                label={phoneField}
                name="phone"
                value={values.phone}
                onChange={setValue}
              />
              <InpuField
                label={companyNameField}
                name="companyName"
                value={values.companyName}
                onChange={setValue}
              />

              <CheckboxField
                id="gdprConsent"
                label={
                  <ActionString value={gdprField}>
                    {(text, link) => (
                      <ExternalLink
                        key={link}
                        to={link!}
                        segmentdata={{
                          anchor_text: text,
                          position: 'body',
                          url: link,
                        }}
                      >
                        {text}
                      </ExternalLink>
                    )}
                  </ActionString>
                }
                onChange={() => setHasGdprConsent(!hasGdprConsent)}
              />
              <CheckboxField
                className={styles.checkbox}
                id="mailingConsent"
                label={
                  <ActionString value={mailingConsentField}>
                    {(text, link) => (
                      <ExternalLink
                        key={link}
                        to={link!}
                        segmentdata={{
                          anchor_text: text,
                          position: 'body',
                          url: link,
                        }}
                      >
                        {text}
                      </ExternalLink>
                    )}
                  </ActionString>
                }
                name="mailingConsent"
                value={hasMailingConsent ? mailingConsentField : ''}
                onChange={onChangeMailingConsent}
                //onChange={() => setHasMailingConsent(!hasMailingConsent)} // optional checkbox. Uncommented to not control disabling on submit btn
              />

              <div className={styles.downloadButtonWrapper}>
                <small className={styles.recaptchaInfo}>
                  <ActionString value={recaptchaDisclaimer}>
                    {(text, link) => (
                      <ExternalLink
                        key={link}
                        to={link!}
                        segmentdata={{
                          anchor_text: text,
                          position: 'body',
                          url: link,
                        }}
                      >
                        {text}
                      </ExternalLink>
                    )}
                  </ActionString>
                </small>
                <CTAButton
                  className={styles.downloadButton}
                  disabled={isDisabled || isLoading}
                >
                  <span className={styles.submitLabel}>{buttonText}</span>

                  <svg className={styles.submitLoader} viewBox="0 0 50 50">
                    <path ref={pathElRef} transform="translate(25 25)" />
                  </svg>
                </CTAButton>
              </div>

              {requestError && !isLoading && (
                <div
                  className={styles.errorMessage}
                  dangerouslySetInnerHTML={{ __html: errorMessage }}
                />
              )}
            </BaseForm>
          )}

          {hasSentSuccessfully && (
            <>
              <h2>{confirmationTitle}</h2>
              {confirmationText && (
                <ContentfulRichText json={confirmationText.json} />
              )}
            </>
          )}
        </div>
      </section>
    </LayoutContainer>
  )
}

export const SignUpFormBlockFragment = graphql`
  fragment SignUpFormBlockFragment on Node {
    ... on ContentfulBlockSignUpForm {
      emailConfirmationSubject
      emailConfirmationTextV2 {
        nodeType
        content {
          content {
            value
          }
        }
        emailConfirmationTextV2
        json
      }
      confirmationTitle
      confirmationText {
        json
      }
      formId
      title
      slug
      bodyText {
        json
      }
      buttonText
    }
  }
`
