import classNames from 'classnames'
import { graphql } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'

import { RichTextNode } from '../../../@import-types/contentful/general.types'
import { CTATargetId } from '../../../constants/GlobalDOM'
import useMicroCopy from '../../../hooks/globalContent/useMicroCopy'
import BaseForm from '../../_elements/BaseForm'
import ContentfulRichText from '../../_elements/ContentfulRichText'
import ExternalLink from '../../_elements/ExternalLink'
import CTAButton from '../../_elements/_buttons/CTAButton'
import AttachmentField from '../../_elements/_inputs/AttachmentField'
import CheckboxField from '../../_elements/_inputs/CheckboxField'
import InputField from '../../_elements/_inputs/InputField'
import TextAreaField from '../../_elements/_inputs/TextAreaField'
import ActionString from '../../_functional/ActionString'
import LayoutContainer from '../../_layouts/LayoutContainer'
import RichTextLayout from '../../_layouts/RichTextLayout'
import SideColumnLayout from '../../_layouts/SideColumnLayout'
import Heading2 from '../../_typography/Heading2'

import {
  useApplyFormInputProps,
  useFormSendState,
} from './ApplyToJobFormBlock.hooks'

import styles from './apply-to-job-form-block.module.scss'

export const ApplyToJobBlockTypename = 'ContentfulBlockApplyToJobForm'

export interface FormOptions {
  issueSummary: string | null | undefined
  projectKey: string | null | undefined
  epicId?: string | null
  assignee?: string | null
}

export interface ApplyToJobFormBlockProps extends FormOptions {
  __typename: typeof ApplyToJobBlockTypename

  title?: string | null
  bodyText?: RichTextNode | null
  buttonText?: string | null
  successMessage?: RichTextNode | null
  errorMessage?: RichTextNode | null
}

export default function ApplyFormBlock({
  title,
  bodyText,
  buttonText,
  successMessage,
  errorMessage,

  projectKey,
  issueSummary,
  epicId,
  assignee,
}: ApplyToJobFormBlockProps) {
  const nameLabel = useMicroCopy('form.name')
  const emailLabel = useMicroCopy('form.email')
  const phoneLabel = useMicroCopy('form.phone')
  const portfolioLabel = useMicroCopy('form.portfolio_url')
  const attachment_1 = useMicroCopy('form.attach_file1')
  const attachment_2 = useMicroCopy('form.attach_file2')
  const describeYourself = useMicroCopy('form.describe_yourself')
  const gdprField = useMicroCopy('form.gdpr')
  const recaptchaDisclaimer = useMicroCopy('form.recaptcha_disclaimer')

  const { getValues, ...inputProps } = useApplyFormInputProps()

  const [hasGivenConsent, setHasGivenConsent] = useState<boolean>(false)

  const hasAttachedFile =
    Boolean(inputProps.attachment_1.value) ||
    Boolean(inputProps.attachment_2.value)

  const { isSending, hasSent, didFail, sendForm } = useFormSendState({
    projectKey,
    issueSummary,
    epicId,
    assignee,
  })

  const submitAnimationRef = useRef<HTMLSpanElement | null>(null)
  const [hasFinishedSendAnimation, setHasFinishedSenndAnimation] =
    useState(false)

  const hasSentAndAnimated = hasSent && hasFinishedSendAnimation

  useEffect(() => {
    const submitAnimationEl = submitAnimationRef.current

    if (!submitAnimationEl) {
      return
    }

    if (isSending) {
      submitAnimationEl.style.transition = 'width 5s'
      submitAnimationEl.style.width = '70%'
    } else {
      submitAnimationEl.style.transition = 'width .3s'
      submitAnimationEl.style.width = hasSent ? '100%' : '0%'

      if (hasSent) {
        submitAnimationEl.addEventListener(
          'transitionend',
          function onTransitionEnd() {
            submitAnimationEl.removeEventListener(
              'transitionend',
              onTransitionEnd
            )

            setTimeout(() => {
              setHasFinishedSenndAnimation(true)
            }, 300)
          }
        )
      }
    }
  }, [isSending, hasSent, didFail])

  function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    sendForm(event.currentTarget, getValues())
  }

  function handleCheckboxFieldChange() {
    setHasGivenConsent(!hasGivenConsent)
  }

  return (
    <LayoutContainer withMargin>
      <SideColumnLayout
        sideColumn={
          title && <Heading2 className={styles.title}>{title}</Heading2>
        }
        mainColumn={
          <>
            {!hasSentAndAnimated && bodyText && bodyText.json && (
              <RichTextLayout className={styles.text}>
                <ContentfulRichText json={bodyText.json} />
              </RichTextLayout>
            )}

            <>
              {!hasSentAndAnimated && (
                <BaseForm
                  className={classNames(
                    styles.form,
                    isSending && styles.formIsSending
                  )}
                  id={CTATargetId}
                  onSubmit={onFormSubmit}
                >
                  <InputField label={nameLabel} {...inputProps.name} />

                  <InputField
                    label={emailLabel}
                    type="email"
                    {...inputProps.email}
                  />

                  <InputField
                    label={phoneLabel}
                    type="tel"
                    isOptional
                    {...inputProps.phone}
                  />

                  <InputField
                    label={portfolioLabel}
                    inputMode="url"
                    isOptional
                    {...inputProps.portfolio}
                  />

                  <AttachmentField
                    label={attachment_1}
                    isOptional
                    accept=".doc,.docx,.pdf"
                    {...inputProps.attachment_1}
                  />

                  {hasAttachedFile && (
                    <AttachmentField
                      label={attachment_2}
                      isOptional
                      accept=".doc,.docx,.pdf"
                      {...inputProps.attachment_2}
                    />
                  )}

                  <TextAreaField
                    label={describeYourself}
                    isOptional
                    {...inputProps.description}
                  />

                  <CheckboxField
                    id="gdprConsent"
                    label={
                      <ActionString value={gdprField}>
                        {(text, link) => (
                          <ExternalLink
                            key={link}
                            to={link!}
                            segmentdata={{
                              anchor_text: text,
                              position: 'body',
                              url: link,
                            }}
                          >
                            {text}
                          </ExternalLink>
                        )}
                      </ActionString>
                    }
                    onChange={handleCheckboxFieldChange}
                  />

                  <div className={styles.formBottomContainer}>
                    <small className={styles.recaptchaInfo}>
                      <ActionString value={recaptchaDisclaimer}>
                        {(text, link) => (
                          <ExternalLink
                            key={link}
                            to={link!}
                            segmentdata={{
                              anchor_text: text,
                              position: 'body',
                              url: link,
                            }}
                          >
                            {text}
                          </ExternalLink>
                        )}
                      </ActionString>
                    </small>

                    <CTAButton
                      className={styles.submitButton}
                      type="submit"
                      disabled={!hasGivenConsent || isSending}
                    >
                      {buttonText}
                      <span
                        className={styles.submitAnimation}
                        ref={submitAnimationRef}
                      />
                    </CTAButton>
                  </div>

                  {didFail && errorMessage && (
                    <RichTextLayout className={styles.text}>
                      <ContentfulRichText json={errorMessage.json} />
                    </RichTextLayout>
                  )}
                </BaseForm>
              )}

              {hasSentAndAnimated && (
                <RichTextLayout className={styles.successMessage}>
                  <ContentfulRichText json={successMessage?.json} />
                </RichTextLayout>
              )}
            </>
          </>
        }
      />
    </LayoutContainer>
  )
}

export const ApplyFormBlockFragment = graphql`
  fragment ApplyToJobFormBlockFragment on Node {
    ... on ContentfulBlockApplyToJobForm {
      issueSummary
      projectKey: jiraProjectKey
      epicId: jiraEpicId
      assignee: jiraAssignee
      title
      bodyText {
        json
      }
      buttonText
      successMessage {
        json
      }
      errorMessage {
        json
      }
    }
  }
`
