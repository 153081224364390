import classNames from 'classnames'
import React, { useRef } from 'react'

import useAnimateElementHeight from '../../../hooks/useAnimateElementHeight'
import HubspotForm from '../../HubspotForm'
import SvgArrowDown from '../../_svg/SvgArrowDown'
import LayoutContainer from '../LayoutContainer'

import styles from './hubspot-download-form.module.scss'


export interface HubspotDocumentDownloadLayoutProps {
  filesTitle: string
  filenameList: string[]
  formId: string
  formTitle: string
  displayDownloadForm: boolean
  formTarget:string
  setDisplayDownloadForm: (value: boolean) => void
}

export default function HubspotDocumentDownload({
  filesTitle,
  filenameList,
  formId,
  formTitle,
  displayDownloadForm,
  formTarget,
  setDisplayDownloadForm,
}: HubspotDocumentDownloadLayoutProps) {

  const filesListItemTitles =
    filenameList &&
    filenameList.map((i) => (
      <li className={styles.filesListItem} key={i}>
        {i}
      </li>
    ))
  function handleCollapseButtonClick() {
    setDisplayDownloadForm(false)
  }

  const containerElRef = useRef<HTMLElement>(null)
  useAnimateElementHeight(containerElRef)

  return (
    <section
      className={classNames(
        styles.downloadForm,
        displayDownloadForm && styles.displayForm
      )}
      ref={containerElRef}
    >
      <LayoutContainer className={styles.container}>
        <div className={styles.filesContainer}>
          <h2 className={styles.formTitle}>{formTitle}</h2>
          <h2 className={styles.filesTitle}>{filesTitle}</h2>
          <ul className={styles.filesList}>{filesListItemTitles}</ul>
        </div>
        <div className={styles.formContainer}>
          <HubspotForm formId={formId} target={formTarget} />
        </div>
      </LayoutContainer>

      <button
        className={styles.collapseButton}
        onClick={handleCollapseButtonClick}
      >
        <SvgArrowDown className={styles.collapseButtonSvg} />
      </button>
    </section>
  )
}
